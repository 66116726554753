import { render, staticRenderFns } from "./EntityCustomizations.vue?vue&type=template&id=34023f00&scoped=true"
import script from "./EntityCustomizations.vue?vue&type=script&lang=js"
export * from "./EntityCustomizations.vue?vue&type=script&lang=js"
import style0 from "./EntityCustomizations.vue?vue&type=style&index=0&id=34023f00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34023f00",
  null
  
)

export default component.exports